h1, h1 + p {
    text-align: center;
}

.controls {
    display: flex;
    flex-wrap: wrap;
}

.field {
    display: flex;
    margin: .5rem 0 0 .5rem;
}

.field button {
    display: block;
    font-size: 1rem;
    padding: .25rem .75rem;
    border: none;
    border-radius: .5rem 0 0 .5rem;
    background-color: #f5f5f5;
    border: 1px solid #dbdbdb;
    border-right: none;
    color: #7a7a7a;
}

.field select, .field input {
    display: block;
    cursor: pointer;
    font-size: 1rem;
    padding: .25rem;
    border: 1px solid #dbdbdb;
    border-radius: 0 .5rem .5rem 0;
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
    max-width: 120px;
}

.field select:active, .field input:active,
.field select:focus, .field input:focus {
    border-color: #485fc7;
    box-shadow: 0 0 0 0.125em rgb(72 95 199 / 25%);
    outline: 0;
}
