.course {
    position: absolute;
    width: 90%;
    margin-left: 5%;
    background-color: #e6ebed;
    color: #333333;
    overflow: hidden;
    z-index: 999;
}

.course-section {
    margin: .25rem .25rem 0 .25rem;
}

.course-room {
    margin: 0 .25rem .25rem .25rem;
}
