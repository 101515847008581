.spinner-container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    width: 8rem;
    height: 8rem;
}

.spinner::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100000px;
    border: 2px solid transparent;
    border-top: 2px solid black;
    animation: .75s linear infinite forwards spin;
    box-sizing: border-box;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
