.room {
    border: 1px solid #e3e3e3;
    border-radius: .5rem;
    margin: .5rem;
    background-color: #f5f5f5;
}

.summary {
    display: flex;
    align-items: center;
    padding: .5rem;
    cursor: pointer;
    user-select: none;
}

.summary > img {
    transition: transform .1s;
}

.summary > span:first-of-type {
    margin: 0 1rem 0 .25rem;
}

.room.expanded > .summary > img {
    transform: rotate(90deg);
}
