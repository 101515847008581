table {
    width: 100%;
    margin-top: .5rem;
    border-collapse: collapse;
    table-layout: fixed;
    color: #333333;
}

td {
    position: relative;
    height: 4rem;
    padding: 0;
    border: 1px solid #c1c1c1;
}

td:first-child {
    width: 4rem;
}

td.time {
    padding-right: .5rem;
    white-space: nowrap;
    text-align: right;
    vertical-align: top;
    border: none;
}

tr:first-child td {
    border: none;
    height: auto;
    text-align: center;
}

tr:first-child td:not(:first-child)::after {
    content: attr(data-mobile);
}

.center {
    padding: 0 .25rem .5rem 0;
}

@media (orientation: landscape) {
    tr:first-child td:not(:first-child)::after {
        content: attr(data-desktop);
    }

    .center {
        display: flex;
        justify-content: center;
    }

    table {
        width: 50%;
        min-width: 650px;
    }
}
